import { UserCardState, UserState, UserTabs, USER_CARD } from '@/features/UserCardContent/store/userCard/userCardTypes'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: UserCardState = {
  user: {
    id: '',
    email: '',
    userName: '',
  },
  tab: UserTabs.PERSONAL_INFO,
  isLoading: false,
}

const userCardSlice = createSlice({
  name: USER_CARD,
  initialState,
  reducers: {
    initUserCardState: (state, { payload: data }: PayloadAction<UserState>) => {
      state.tab = 1
      if (data) state.user = { ...data }
    },
    onTabChange: (state, { payload: tabNumber }: PayloadAction<number>) => {
      state.tab = tabNumber
    },
    resetUserCardSlice: () => initialState,
    setLoading: (state, { payload: isLoading }: PayloadAction<boolean>) => {
      state.isLoading = isLoading
    },
  },
})

export default userCardSlice.reducer

export const { initUserCardState, onTabChange, resetUserCardSlice, setLoading } = userCardSlice.actions
