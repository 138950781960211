export enum SnackTypes {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
}

export type SnackModel = {
  openSnack: boolean
  typeSnack: SnackTypes.SUCCESS | SnackTypes.WARNING | SnackTypes.ERROR
  messageSnack?: string
}

export type SnackStateType = {
  snack: SnackModel
}
