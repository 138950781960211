import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type UserType = {
  userName: string
  email: string
  fullName: string
  id: string
}
type UserResponse = {
  data: UserType
  description: string
}

type UserStateModel = {
  currentUser: UserType | null
}

export const USER_SLICE_NAME = 'user'

const initialState: UserStateModel = {
  currentUser: null,
}

const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<UserResponse>) => {
      state.currentUser = payload.data
    },
  },
})

export default userSlice.reducer
export const { setCurrentUser } = userSlice.actions
