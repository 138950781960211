export const ROLE_CARD = 'ROLE_CARD'

type ProjectType = {
  id: number
  name: string
  viewName: string
}

export type RoleState = {
  id: string
  name: string
  displayName: string
  isForAllProjects: boolean
  projects: ProjectType[]
}

export type RoleCardState = {
  role: RoleState
  isLoading: boolean
}
