import { AccessRightsState, AccessRightsTabs, ACCESS_RIGHTS } from '@/features/AccessRights/store/accessRightsTypes'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: AccessRightsState = {
  tab: AccessRightsTabs.PROSYSTEM,
  isLoading: false,
}

const accessRightsSlice = createSlice({
  name: ACCESS_RIGHTS,
  initialState,
  reducers: {
    initAccessRightsState: (state) => {
      state.tab = 1
    },
    onTabChange: (state, { payload: tabNumber }: PayloadAction<number>) => {
      state.tab = tabNumber
    },
    resetAccessRightsSlice: () => initialState,
    setLoading: (state, { payload: isLoading }: PayloadAction<boolean>) => {
      state.isLoading = isLoading
    },
  },
})

export default accessRightsSlice.reducer

export const { initAccessRightsState, onTabChange, resetAccessRightsSlice, setLoading } = accessRightsSlice.actions
