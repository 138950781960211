export const ACCESS_RIGHTS = 'ACCESS_RIGHTS'

export enum AccessRightsTabs {
  PROSYSTEM = 1,
  PROLEARNING = 2,
  PROANALYTICS = 3,
  PROMARKET = 4,
  F3PRONEW = 5,
  PROAPTEKA_PROFILE = 6,
  PROPASSPORT = 7,
  GENERAL = 8,
}

export type AccessRightsState = {
  tab: AccessRightsTabs
  isLoading: boolean
}
