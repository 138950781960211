import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { profileApiUrl } from '@/env.config'

const baseQuery = fetchBaseQuery({
  baseUrl: profileApiUrl,
  prepareHeaders: (headers) => headers,
  credentials: 'include',
})

export const apiSlice = createApi({
  baseQuery,
  reducerPath: 'splitApi',
  tagTypes: ['Roles', 'UserRoles', 'ClaimCategories', 'PersonalClaim', 'AllUserClaims', 'RoleClaims'],
  endpoints: () => ({}),
})
