import {
  combineReducers,
  configureStore,
  ConfigureStoreOptions,
  createListenerMiddleware,
  ListenerEffectAPI,
  TypedAddListener,
  TypedStartListening,
} from '@reduxjs/toolkit'
import { apiSlice } from '@/shared/store/apiSlice'
import { TypedUseSelectorHook, useSelector } from 'react-redux'
import tableSettings from '@/shared/store/table/tableSlice'
import appSettingsSlice from '@/shared/store/appSettings/appSettingsSlice'
import filterSlice from '@/shared/store/filters/filtersSlice'
import userSlice from '@/shared/store/user/userSlice'
import notificationsSlice from '@/shared/store/notifications/notificationsSlice'
import modalSlice from '@/shared/store/modal/modalSlice'
import filtersUsersTableSlice from '@/features/UsersTable/store/filtersUsersTable/filtersUsersTableSlice'
import tableSelectionSlice from '@/features/UsersTable/store/tableSelection/tablseSelectionSlice'
import tableSortingSlice from '@/features/UsersTable/store/tableSorting/tableSortingSlice'
import snackSlice from '@/shared/store/snack/snackSlice'
import userCardSlice from '@/features/UserCardContent/store/userCard/userCardSlice'
import accessRightsSlice from '@/features/AccessRights/store/accessRightsSlice'
import filtersRolesTableSlice from '@/pages/Role/store/filtersRolesTable/filtersRolesTableSlice'
import rolesTableSelectionSlice from '@/pages/Role/store/tableSelection/tableSelectionSlice'
import roleCardSlice from '@/features/RoleCardContent/store/roleCard/roleCardSlice'
import filtersAccessRightsTableSlice from '@/features/AccessRights/store/filters/filtersAccessRightsTableSlice'

export const rootReducer = combineReducers({
  tableSettings,
  appSettingsSlice,
  filterSlice,
  filtersUsersTableSlice,
  tableSelectionSlice,
  tableSortingSlice,
  userSlice,
  notificationsSlice,
  modalSlice,
  snackSlice,
  userCardSlice,
  filtersRolesTableSlice,
  rolesTableSelectionSlice,
  roleCardSlice,
  accessRightsSlice,
  filtersAccessRightsTableSlice,
  [apiSlice.reducerPath]: apiSlice.reducer,
})

const listenerMiddlewareInstance = createListenerMiddleware({
  onError: () => console.error,
})

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddlewareInstance.middleware).concat(apiSlice.middleware),
    ...options,
    devTools: true,
  })

export const store = createStore()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>
export type AppStartListening = TypedStartListening<RootState, AppDispatch>
export type AppAddListener = TypedAddListener<RootState, AppDispatch>
