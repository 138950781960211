export const FILTERS_USERS_TABLE = 'FILTERS_USERS_TABLE'

export type FiltersUsersTableStateType = {
  errors: any
  filters: any
  unsavedFilters: any
}

export interface TableFiltersSelectionType {
  userFilterKey?: string | null
  userPickAll?: boolean
  userValue?: any[] | null
  userIgnoreList?: any[] | null
  userSearch?: string
}
