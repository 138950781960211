import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TableSelectionStateType } from './tableSelectionTypes'

const TABLE_SELECTION = 'TABLE_SELECTION'

const initialState: TableSelectionStateType = {
  selection: {},
}

const tableSelectionSlice = createSlice({
  name: TABLE_SELECTION,
  initialState,
  reducers: {
    initSelectionState: (state) => {
      state.selection = {
        ...state.selection,
        selected: [],
        selectAll: false,
        ignoreList: [],
      }
    },

    setSelectionValues: (
      state,
      { payload: { selected, ignoreList, selectAll } }: PayloadAction<{ selected?: string[]; ignoreList?: string[]; selectAll?: boolean }>
    ) => {
      state.selection = { ...state.selection, selected: selected, ignoreList: ignoreList, selectAll: selectAll }
    },

    updateSelectionValues: (state, { payload: { selectedValue } }: PayloadAction<{ selectedValue: string }>) => {
      if (selectedValue) state.selection = { ...state.selection, selected: [...state.selection.selected, selectedValue] }
    },

    updateIgnoreListSelectionValues: (state, { payload: { ignoreValue } }: PayloadAction<{ ignoreValue: string }>) => {
      if (ignoreValue) state.selection = { ...state.selection, ignoreList: [...state.selection.ignoreList, ignoreValue] }
    },

    removeSelectionValues: (state, { payload: { valueToRemove } }: PayloadAction<{ valueToRemove: string }>) => {
      if (valueToRemove)
        state.selection = {
          ...state.selection,
          selected: [...state.selection.selected.filter((selectedValue: string) => selectedValue !== valueToRemove)],
        }
    },

    removeIgnoreListSelectionValues: (state, { payload: { ignoreValueToRemove } }: PayloadAction<{ ignoreValueToRemove: string }>) => {
      if (ignoreValueToRemove)
        state.selection = {
          ...state.selection,
          ignoreList: [...state.selection.ignoreList.filter((ignoreValue: string) => ignoreValue !== ignoreValueToRemove)],
        }
    },

    resetSelectionSlice: () => initialState,
  },
})

export default tableSelectionSlice.reducer

export const {
  initSelectionState,
  setSelectionValues,
  updateSelectionValues,
  updateIgnoreListSelectionValues,
  removeSelectionValues,
  removeIgnoreListSelectionValues,
  resetSelectionSlice,
} = tableSelectionSlice.actions
