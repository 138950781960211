export const FILTERS_ROLES_TABLE = 'FILTERS_ROLES_TABLE'

export type FiltersRolesTableStateType = {
  errors: any
  filters: any
  unsavedFilters: any
}

export interface TableFiltersSelectionType {
  roleFilterKey?: string | null
  rolePickAll?: boolean
  roleValue?: any[] | null
  roleIgnoreList?: any[] | null
  roleSearch?: string
}
