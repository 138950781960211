import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const TABLE_SORTING = 'TABLE_SORTING'

type TableSortingStateType = {
  sorting: any
}

const initialState: TableSortingStateType = {
  sorting: {},
}

const tableSortingSlice = createSlice({
  name: TABLE_SORTING,
  initialState,
  reducers: {
    initSortingState: (state) => {
      state.sorting = {
        ...state.sorting,
        sort: false,
      }
    },
    setSorting: (state, { payload: { isSorted } }: PayloadAction<{ isSorted: boolean }>) => {
      state.sorting = {
        ...state.sorting,
        sort: isSorted,
      }
    },
    resetSortingSlice: () => initialState,
  },
})

export default tableSortingSlice.reducer

export const { initSortingState, setSorting, resetSortingSlice } = tableSortingSlice.actions
