import _ from 'lodash'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FILTERS_MODULE, FilterStateType, initFilterStateActionType } from '@/shared/store/filters/filtersTypes'

import { FilterModel } from '@proapteka/filters'

// Слайс для фильтров, разделен на основные, и скрытые
const initialState: FilterStateType = {
  requiredFilters: [], // Ключи обязательных для заполнения фильтров
  errors: {},
  filters: {},
  unappliedFilter: true,
  firstApplied: false,
}

const filtersSlice = createSlice({
  name: FILTERS_MODULE,
  initialState,
  reducers: {
    // Инициализация стейта для фильтра
    initFilterState: (state, { payload: { id, scope, defaultValue, label } }: PayloadAction<initFilterStateActionType>) => {
      const newFilter: FilterModel = {
        error: '',
        isLoading: false,
        selected: defaultValue,
        isTokenFilter: false,
        scope,
        label,
      }
      state.filters = { ...state.filters, [id]: newFilter }
    },

    // Установка значения выбранного фильтра
    setFilterValues: (state, { payload: { id, value } }: PayloadAction<{ id: string; value: FilterModel['selected'] }>) => {
      if (!(id in state.filters)) throw new Error(`Filter - ${id} is not defined`)
      state.filters[id].selected = value
      state.unappliedFilter = true
    },

    // Установка ошибки выбранного фильтра
    setFilterError: (state, { payload: { error, id } }: PayloadAction<{ id: string; error: string }>) => {
      state.filters[id].error = error
    },

    // Сброс фильтров по признаку - скоп = дополнительные
    resetFilters: (state, { payload }: PayloadAction<{ scope: string } | undefined>) => {
      state.filters = Object.entries(state.filters).reduce((acc, [id, filter]) => {
        if (payload?.scope && filter.scope !== payload?.scope) {
          return { ...acc, [id]: filter }
        } else if (_.isArray(filter.selected)) return { ...acc, [id]: { ...filter, selected: [] } }
        else return { ...acc, [id]: { ...filter, selected: {} } }
      }, {})
      state.unappliedFilter = true
    },

    // Установка списка обязательных фильтров
    setRequiredFilters: (state, { payload }: PayloadAction<string[]>) => {
      state.requiredFilters = payload
    },

    // Свитч флага является ли фильтр - токен фильтром
    setFilterTokenType: (state, { payload: { id, value } }: PayloadAction<{ id: string; value: boolean }>) => {
      if (!(id in state.filters)) throw new Error(`Filter - ${id} is not defined`)
      state.filters[id].isTokenFilter = value
    },

    setRestoredOptions: (state, { payload: filterData }: PayloadAction<{ id: string; data: Record<string, any>[] }[]>) => {
      Object.entries(state.filters).map(([key, filter]) => {
        const restoredFilterData = filterData.find((item) => item.id === key)
        if (restoredFilterData) filter.selected = restoredFilterData.data
      })
    },

    // Переопределение стейтов если загрузка и парсинг файла прошли успешно
    setSuccessFileFilterUpload: (
      state,
      {
        payload: { id, token, selectedIds },
      }: PayloadAction<{
        id: string
        token: string
        selectedIds: number[]
      }>
    ) => {
      if (!(id in state.filters)) throw new Error(`Filter - ${id} is not defined`)
      const count = selectedIds.length
      state.filters[id].filterTokenState = {
        actualCount: count,
        totalCount: count,
        isError: false,
        isFileUploaded: true,
        token,
        selectedIds,
      }
      state.unappliedFilter = true
    },
    //
    // // Обновление стейта токен фильтра
    // updateTokenFilterIds: (state, {payload: {id, selectedIds}}: PayloadAction<{
    //   id: string,
    //   selectedIds: number[]
    // }>) => {
    //   if (state.filters[id].filterTokenState) {
    //     state.filters[id].filterTokenState = {
    //       ...state.filters[id].filterTokenState as FilterTokenModel,
    //       selectedIds,
    //       totalCount: selectedIds.length
    //     }
    //     state.unappliedFilter = true
    //   }
    // },

    // // Обновить количество актуальных элементов токен фильтра
    // updateTokenFilterActualCount: (state, {payload: {actualCount, id}}: PayloadAction<{
    //   id: string,
    //   actualCount: number
    // }>) => {
    //   if (state.filters[id].filterTokenState !== undefined) {
    //     state.filters[id].filterTokenState = {
    //       ...state.filters[id].filterTokenState as FilterTokenModel,
    //       actualCount
    //     }
    //     state.unappliedFilter = true
    //   }
    // },

    // // Полностью сбросить стейт токен фильтра
    // clearTokenFilter: (state, {payload: {id}}: PayloadAction<{id: string}>) => {
    //   if (!(id in state.filters)) throw new Error(`Filter - ${id} is not defined`)
    //   state.filters[id].filterTokenState = undefined
    //   state.unappliedFilter = true
    // },

    applyFilters: (state) => {
      state.unappliedFilter = false
      state.firstApplied = true
    },
    resetFiltersSlice: () => initialState,
  },
})

export default filtersSlice.reducer

export const {
  resetFilters,
  setFilterTokenType,
  resetFiltersSlice,
  initFilterState,
  setFilterValues,
  setFilterError,
  setSuccessFileFilterUpload,
  applyFilters,
  setRestoredOptions,
} = filtersSlice.actions
