export const FILTERS_ACCESS_RIGHTS_TABLE = 'FILTERS_ACCESS_RIGHTS_TABLE'

export type FiltersAccessRightsTable = {
  globalFilter: string
}

export type FiltersAccessRightsTableStateType = {
  errors: any
  filters: any
  unsavedFilters: any
}

export interface TableFiltersSelectionType {
  accessRightsFilterKey?: string | null
  accessRightsPickAll?: boolean
  accessRightsValue?: any[] | null
  accessRightsIgnoreList?: any[] | null
  accessRightsSearch?: string
}
