import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Все существующие варианты модалок
export type ModalsType = 'settings' | 'columns' | 'filters' | 'filterToken' | 'banUsers'

// Все доступные ключи фильтров для токен фильтра
export type tokenFilterAllowedIds =
  | 'producerIds'
  | 'concernIds'
  | 'distributorIds'
  | 'goodIds'
  | 'departmentIds'
  | 'contractorIds'
  | 'managerIds'
  | 'ekgIds'
  | 'cvpProductIds'

export type DataModalTokenFilter = {
  filterId: tokenFilterAllowedIds
  isUploadError?: boolean
}

export type DataModalsType = DataModalTokenFilter | null

type ModalStateType = {
  isOpen: boolean
  modalType: ModalsType | null
  data?: DataModalsType
}

const MODAL_MODULE = 'MODAL'

const initialState: ModalStateType = {
  isOpen: false,
  modalType: null,
  data: null,
}

const modalSlice = createSlice({
  name: MODAL_MODULE,
  initialState,
  reducers: {
    openModal(state, { payload: { modalType, data } }: { payload: Omit<ModalStateType, 'isOpen'> }) {
      state.isOpen = true
      state.modalType = modalType
      if (data) state.data = data
    },
    closeModal(state) {
      state.isOpen = false
      state.modalType = null
    },
    setDataModal(state, { payload }: PayloadAction<DataModalsType>) {
      state.data = payload
    },
  },
})

export default modalSlice.reducer

export const { openModal, closeModal, setDataModal } = modalSlice.actions
