import { RoleCardState, RoleState, ROLE_CARD } from '@/features/RoleCardContent/store/roleCard/roleCardTypes'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: RoleCardState = {
  role: {
    id: '',
    name: '',
    displayName: '',
    isForAllProjects: false,
    projects: [],
  },
  isLoading: false,
}

const roleCardSlice = createSlice({
  name: ROLE_CARD,
  initialState,
  reducers: {
    initRoleCardState: (state, { payload: data }: PayloadAction<RoleState>) => {
      if (data) state.role = { ...data }
    },
    resetRoleCardSlice: () => initialState,
    setLoading: (state, { payload: isLoading }: PayloadAction<boolean>) => {
      state.isLoading = isLoading
    },
  },
})

export default roleCardSlice.reducer

export const { initRoleCardState, resetRoleCardSlice, setLoading } = roleCardSlice.actions
