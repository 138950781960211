import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SnackStateType, SnackTypes } from '@/shared/store/snack/snackTypes'

export const SNACK_MODULE = 'SNACK'

const initialState: SnackStateType = {
  snack: {
    openSnack: false,
    typeSnack: SnackTypes.SUCCESS,
    messageSnack: '',
  },
}

const snackSlice = createSlice({
  name: SNACK_MODULE,
  initialState,
  reducers: {
    showSnack: (state, { payload: { typeSnack, messageSnack } }: PayloadAction<{ typeSnack: SnackTypes; messageSnack: string }>) => {
      state.snack = { openSnack: true, typeSnack, messageSnack }
    },
    hideSnack: (state) => {
      state.snack = { ...state.snack, openSnack: false }
    },
  },
})

export default snackSlice.reducer

export const { showSnack, hideSnack } = snackSlice.actions
