import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'

import { Provider as StoreProvider } from "react-redux";
import {store} from "@/shared/store/store";

import {oAuthControllerInstance} from "@/shared/axios/instance";
import {Preloader} from "@/shared/components/UI/Preloader/Preloader";
import {OIDCAuthIFrame} from "@proapteka/oidc-auth-iframe";

import './index.css'
import 'primeicons/primeicons.css';


const App = React.lazy(() => import("@/App"))

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StoreProvider store={store}>
    <OIDCAuthIFrame authControllerInstance={oAuthControllerInstance} preloader={<Preloader />}>
      <Suspense fallback={<Preloader />}>
        <App />
      </Suspense>
    </OIDCAuthIFrame>
  </StoreProvider>
)
