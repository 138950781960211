export const USER_CARD = 'USER_CARD'

export type UserState = {
  id: string
  firstName?: string
  lastName?: string
  patronymic?: string
  birthDate?: any
  tin?: any
  email: string
  phone?: string
  userName: string
  timeZone?: string
  isEmailConfirmed?: boolean
  isPhoneNumberConfirmed?: boolean
  isPersonalDataAgreement?: boolean
  personalDataAgreementDate?: any
  isPromotionSubscribe?: boolean
  promotionSubscribeDate?: any
  isLearningAgreement?: boolean
  learningAgreementDate?: any
  comment?: string
  isBlocked?: number
  lockoutEnd?: any
  reasonBlock?: string
  timeEntry?: string
  mainDepartment?: any
  mainContractor?: any
  isInAllProject?: boolean
  isInAllDepartment?: boolean
  projects?: any
  roles?: any
  assignDepartments?: any
  assignContractors?: any
  createdAt?: string
  supervisor?: any
}

export enum UserTabs {
  PERSONAL_INFO = 1,
  SYSTEMS_AND_ROLES = 2,
}

export type UserCardState = {
  user: UserState
  tab: UserTabs
  isLoading: boolean
}
